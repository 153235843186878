import * as React from "react"

import { cn } from "#app/components/utils"
import { cva, VariantProps } from "class-variance-authority"

const inputVariants = cva("flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 read-only:opacity-50",{
  variants: {
    variant: {
      default: "",
      dark: 'bg-darkInput-background border-darkInput-border ring-offset-darkInput-border text-white',
      menu: 'bg-menu-bg-foreground text-menu-text-light border-none p-4'
    },
    
  },
  defaultVariants: {
    variant: "default",
  },
})


export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>, VariantProps<typeof inputVariants> {}

  
const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, variant,...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          inputVariants({variant}),
          className
        )}
        ref={ref}
        {...props}   
      />
    )
  }
)
Input.displayName = "Input"

export { Input }
